import { axiosInstance } from '../api/axiosConfig';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ProductImageGallery from '../components/productDetail/ProductImageGallery';
import RelatedProducts from '../components/productDetail/RelatedProducts';
import { addToCart, removeFromCart } from '../features/cart/cartSlice';
import { addToFavourites, removeFromFavourites } from '../features/favourites/favouritesSlice';
import { Nav, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as farStar } from '@fortawesome/free-solid-svg-icons';
import './ProductDetailPage.scss';

const ProductDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cart.items);
  const favouriteItems = useSelector(state => state.favourites.items);
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isFavourited, setIsFavourited] = useState(false);
  const [isInCart, setIsInCart] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axiosInstance.get(`/products/products/${id}/`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    const fetchRelatedProducts = async () => {
      try {
        const response = await axiosInstance.get(`http://localhost:8000/products/products/${id}/related/`);
        setRelatedProducts(response.data);
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    fetchProduct();
    fetchRelatedProducts();
  }, [id]);

  useEffect(() => {
    setIsFavourited(favouriteItems.some(item => item.id === parseInt(id)));
    setIsInCart(cartItems.some(item => item.id === parseInt(id)));
  }, [favouriteItems, cartItems, id]);

  const handleAddToCart = () => {
    if (isInCart) {
      dispatch(removeFromCart(product));
    } else {
      dispatch(addToCart(product));
    }
    setIsInCart(!isInCart);
  };

  const handleAddToFavourites = () => {
    if (isFavourited) {
      dispatch(removeFromFavourites(product));
    } else {
      dispatch(addToFavourites(product));
    }
    setIsFavourited(!isFavourited);
  };

  const handleBuyNow = () => {
    // Implement Buy Now functionality
    alert("Buy Now functionality not implemented yet");
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array(fullStars).fill(<FontAwesomeIcon icon={faStar} className="star-icon" />)}
        {halfStar && <FontAwesomeIcon icon={faStarHalfAlt} className="star-icon" />}
        {Array(emptyStars).fill(<FontAwesomeIcon icon={farStar} className="star-icon" />)}
      </>
    );
  };

  if (!product) return <div>Loading...</div>;

  return (
    <div className="product-detail-page">
      <div className="product-main-section">
        <div className="product-image-gallery">
          <ProductImageGallery images={product.gallery_images} />
        </div>
        <div className="product-info">
          <h1>{product.name}</h1>
          <p className="product-price">Price: £{product.price}</p>
          <div className="reviews-summary">
            {renderStars(product.average_rating)} ({product.review_count} reviews)
          </div>
          <div className="product-buttons">
            <button onClick={handleAddToCart}>
              {isInCart ? 'Remove from Cart' : 'Add to Cart'}
            </button>
            <button onClick={handleAddToFavourites}>
              {isFavourited ? 'Remove from Favourites' : 'Add to Favourites'}
            </button>
            <button onClick={handleBuyNow}>Buy Now</button>
          </div>
          <Tab.Container defaultActiveKey="description">
            <Nav variant="tabs" className="product-tabs">
              <Nav.Item>
                <Nav.Link eventKey="description">Description</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="specs">Specifications</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="reviews">Reviews</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="description">
                <div className="product-description">
                  <p>{product.description}</p>
                </div>
                </Tab.Pane>
              <Tab.Pane eventKey="specs">
                <div className="product-specs">
                  <div><strong>Brand:</strong> {product.brand}</div>
                  <div><strong>Eco-friendly:</strong> {product.eco_friendly ? 'Yes' : 'No'}</div>
                  <div><strong>Stock:</strong> {product.stock}</div>
                  <div><strong>Category ID:</strong> {product.category_id}</div>
                  <div><strong>Highlight:</strong> {product.highlight ? 'Yes' : 'No'}</div>
                  <div><strong>Product Type:</strong> {product.product_type}</div>
                  <div><strong>Color:</strong> {product.color}</div>
                  <div><strong>Dimensions:</strong> {product.dimensions}</div>
                  {product.weight && (
                    <div>
                      <strong>Weight:</strong> {product.weight} kg
                    </div>
                  )}
                  {product.material && (
                    <div>
                      <strong>Material:</strong> {product.material}
                    </div>
                  )}
                  {product.license_key && (
                    <div>
                      <strong>License Key:</strong> {product.license_key}
                    </div>
                  )}
                  {product.video_demo && (
                    <div>
                      <strong>Video Demo:</strong> <a href={product.video_demo} target="_blank" rel="noopener noreferrer">Watch here</a>
                    </div>
                  )}
                  {product.file_url && (
                    <div>
                      <strong>File URL:</strong> <a href={product.file_url} target="_blank" rel="noopener noreferrer">Download here</a>
                    </div>
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="reviews">
                <div className="product-reviews">
                  <h3>Reviews</h3>
                  {product.reviews.length > 0 ? (
                    product.reviews.map((review, index) => (
                      <div key={index}>
                        <strong>{review.user}:</strong> {review.comment}
                      </div>
                    ))
                  ) : (
                    <p>No reviews yet</p>
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <div className="related-products">
        <h2>Related Products</h2>
        <RelatedProducts products={relatedProducts} />
      </div>
    </div>
  );
};

export default ProductDetailPage;