import React, { useState, useEffect } from 'react';
import { elasticsearchInstance } from '../api/axiosConfig';
import FilterSidebar from '../components/productCatalogue/FilterSidebar';
import Pagination from '../components/productCatalogue/Pagination';
import ProductCard from '../components/productCatalogue/ProductCard';
import Welcome from '../components/productCatalogue/Welcome';
import './ProductCatalogue.scss';

const ProductCatalogueWithFilters = () => {
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    query: '',
    category: '',
    priceRange: [0, 100],
    brand: '',
    productType: '',
    ordering: '',
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchProducts = async (currentFilters, page = 1) => {
    try {
      console.log('Fetching products with filters:', currentFilters);

      const elasticsearchQuery = {
        bool: {
          must: [],
          filter: [],
        },
      };

      if (currentFilters.query) {
        elasticsearchQuery.bool.must.push({
          multi_match: {
            query: currentFilters.query,
            fields: ['name', 'description', 'category.name', 'brand', 'product_type'],
          },
        });
      }

      if (currentFilters.category) {
        elasticsearchQuery.bool.filter.push({ term: { 'category.name.keyword': currentFilters.category } });
      }

      if (currentFilters.priceRange) {
        elasticsearchQuery.bool.filter.push({
          range: {
            price: {
              gte: currentFilters.priceRange[0],
              lte: currentFilters.priceRange[1],
            },
          },
        });
      }

      if (currentFilters.brand) {
        elasticsearchQuery.bool.filter.push({ term: { brand: currentFilters.brand } });
      }

      if (currentFilters.productType) {
        elasticsearchQuery.bool.filter.push({ term: { 'product_type.keyword': currentFilters.productType } });
      }

      console.log('Elasticsearch query:', JSON.stringify(elasticsearchQuery, null, 2));

      const response = await elasticsearchInstance.post('/products_v5/_search', {
        query: elasticsearchQuery,
        from: (page - 1) * 10,
        size: 10,
      });

      console.log('Elasticsearch response:', response.data);

      if (response.data.hits) {
        console.log('Elasticsearch hits:', response.data.hits.hits);
        setProducts(response.data.hits.hits);
        setTotalPages(Math.ceil(response.data.hits.total.value / 10));
      } else {
        console.error('No hits in the response:', response.data);
        setProducts([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setInitialLoad(false);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      console.log('Initial load:', initialLoad);
      fetchProducts(filters);
    }
  }, [initialLoad, filters]); // Added filters to the dependency array

  const handleFilterChange = (newFilters) => {
    console.log('Filters changed:', newFilters);
    setFilters(newFilters);
    fetchProducts(newFilters, 1); // Reset to first page when filters change
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    console.log('Page changed to:', page);
    setCurrentPage(page);
    fetchProducts(filters, page);
  };

  return (
    <div className="container-fluid product-catalogue">
      <div className="row">
        <div className="col-lg-3">
          <FilterSidebar onChange={handleFilterChange} />
        </div>
        <div className="col-lg-9">
          <div className="product-list">
            <Welcome />
            {!initialLoad && products && products.length > 0 ? (
              <div className="row">
                {products.map((product) => (
                  <div key={product._id} className="col-md-4 mb-4">
                    <ProductCard product={product._source} />
                  </div>
                ))}
              </div>
            ) : (
              !initialLoad && <p>No products found</p>
            )}
            {!initialLoad && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCatalogueWithFilters;