import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './FilterSidebar.scss';
import { elasticsearchInstance } from '../../api/axiosConfig';

const FilterSidebar = ({ onChange }) => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [filters, setFilters] = useState({
    query: '',
    category: '',
    priceRange: [0, 100],
    brand: '',
    productType: '',
  });
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        console.log('Fetching filters...');
        const response = await elasticsearchInstance.post('/products_v5/_search', {
          size: 0,
          aggs: {
            categories: { terms: { field: 'category.name.keyword', size: 10 } },
            brands: { terms: { field: 'brand.keyword', size: 10 } }, // Ensure 'brand.keyword' is used
            productTypes: { terms: { field: 'product_type.keyword', size: 10 } }
          },
        });

        console.log('Elasticsearch response:', response.data);

        if (response.data.aggregations) {
          console.log('Aggregations found in response:', response.data.aggregations);

          const fetchedCategories = response.data.aggregations.categories.buckets.map((bucket) => ({
            name: bucket.key,
            count: bucket.doc_count,
          }));
          const fetchedBrands = response.data.aggregations.brands.buckets.map((bucket) => ({
            name: bucket.key,
            count: bucket.doc_count,
          }));
          const fetchedProductTypes = response.data.aggregations.productTypes.buckets.map((bucket) => ({
            name: bucket.key,
            count: bucket.doc_count,
          }));

          console.log('Filters fetched:', {
            categories: fetchedCategories,
            brands: fetchedBrands,
            productTypes: fetchedProductTypes,
          });

          setCategories(fetchedCategories);
          setBrands(fetchedBrands);
          setProductTypes(fetchedProductTypes);
        } else {
          console.error('Aggregations not found in response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();
  }, []);

  const handleChange = (name, value) => {
    console.log(`Filter ${name} changed to:`, value);
    const newFilters = {
      ...filters,
      [name]: value,
    };
    setFilters(newFilters);
    onChange(newFilters);
  };

  const handleSliderChange = (name) => (value) => {
    handleChange(name, value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    onChange(filters);
  };

  return (
    <div className={`filter-sidebar ${collapsed ? 'collapsed' : ''}`}>
      <button
        className="collapse-button btn btn-secondary mb-3"
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? 'Expand Filters' : 'Collapse Filters'}
      </button>
      <h2 className="h4 mb-4">Filters</h2>
      {!collapsed && (
        <form onSubmit={handleSearchSubmit}>
          <div className="filter-group mb-4">
            <label htmlFor="query" className="form-label">Search</label>
            <input
              type="text"
              name="query"
              id="query"
              value={filters.query}
              onChange={(e) => handleChange('query', e.target.value)}
              className="form-control"
            />
          </div>
          <div className="filter-group mb-4">
            <label htmlFor="category" className="form-label">Category</label>
            <select
              name="category"
              id="category"
              value={filters.category}
              onChange={(e) => handleChange('category', e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              {categories.map((category) => (
                <option key={category.name} value={category.name}>{category.name}</option>
              ))}
            </select>
          </div>
          <div className="filter-group mb-4">
            <label className="form-label">Price Range (£)</label>
            <Slider
              range
              min={0}
              max={100}
              value={filters.priceRange}
              onChange={handleSliderChange('priceRange')}
              className="form-range"
            />
            <span>{`£${filters.priceRange[0]} - £${filters.priceRange[1]}`}</span>
          </div>
          <div className="filter-group mb-4">
            <label htmlFor="brand" className="form-label">Brand</label>
            <select
              name="brand"
              id="brand"
              value={filters.brand}
              onChange={(e) => handleChange('brand', e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              {brands.map((brand) => (
                <option key={brand.name} value={brand.name}>{brand.name}</option>
              ))}
            </select>
          </div>
          <div className="filter-group mb-4">
            <label htmlFor="productType" className="form-label">Product Type</label>
            <select
              name="productType"
              id="productType"
              value={filters.productType}
              onChange={(e) => handleChange('productType', e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              {productTypes.map((type) => (
                <option key={type.name} value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
          <button type="submit" className="btn btn-primary">Search</button>
        </form>
      )}
    </div>
  );
};

export default FilterSidebar;